<template>
  <div class="diy_pop" v-if="isShowOpenAppPopup">
    <div class="pop_con">
      <van-icon class="close_icon" @click="isShowOpenAppPopup = false" name="cross" />
      <van-cell title="往约 您身边的私人健康师" :center="true">
        <template #icon>
          <GlobalImage class="margin-r10 logo" :src="getImageUrl('public', 'logo.png')"></GlobalImage>
        </template>
        <template #title>
          <b class="fs-13">往约APP</b>
          <div class="fs-11 sub_title">往约一点，轻松到家</div>
        </template>
        <template #right-icon>
          <van-button id="installBtn" class="btn" type="primary" size="mini" round>打开APP</van-button>
        </template>
      </van-cell>
    </div>
  </div>
</template>

<script setup>
import { inject, watch } from 'vue-demi'
import { getImageUrl, getUrlData, isAndroidWeb, loadScript } from '@/utils'
import { jumpApp } from '@/hooks/useOpenApp'

const isShowOpenAppPopup = inject('isShowOpenAppPopup')
watch(
  () => isShowOpenAppPopup.value,
  async (n, o) => {
    if (n) {
      await loadScript('https://cdn.xinstall.com/xinstall.js')
      openApp()
    }
  }
)

const openApp = async () => {
  var data = XInstall.parseUrlParams() //xinstall.js中提供的工具函数，解析url中的所有查询参数，函数返回格式为js Object
  new XInstall(
    {
      /*xinstall中应用的唯一标识，用于识别您的应用*/
      appKey: 'zeaugnj',
      /*xinstall初始化完成的回调函数*/
      onready: function () {
        let that = this
        let installBtn = document.getElementById('installBtn')
        installBtn.onclick = function () {
          if (isAndroidWeb() && getUrlData('channelCode') && ['hyspkpaz', 'hyzbxfaz'].includes(getUrlData('channelCode'))) {
            window.location.href = 'https://oss.wangyuedaojia.com/apks/%E5%BE%80%E7%BA%A6_v3.8.32_' + getUrlData('channelCode') + '.apk'
          } else {
            that.wakeupOrInstall()
          }
        }
      },
    },
    data
  )
}
</script>

<style lang="scss" scoped>
.diy_pop {
  position: fixed;
  height: 54px;
  left: 50%;
  bottom: 60px;
  transform: translateX(-50%);
  z-index: 2023;
  .pop_con {
    width: 350px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    .close_icon {
      position: absolute;
      right: 0;
      top: 0;
      padding: 4px;
      z-index: 2;
      background: #605b4a;
      border-radius: 0 0 0 12px;
      color: #fff;
    }
    :deep(.van-cell) {
      background: rgba(16, 11, 0, 0.8);
      color: #fff;
      padding: 10px;
      .logo {
        width: 36px;
        height: 36px;
        border: 1.5px solid #fff;
        border-radius: 10px;
        overflow: hidden;
      }
      .sub_title {
        color: #bbb;
        margin-top: -5px;
      }
    }
    .btn {
      height: 28px;
      width: 74px;
      margin-right: 12px;
      color: #463c00;
      font-size: 13px;
    }
  }
}
</style>
